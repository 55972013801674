import { StandaloneMileageReportModel, MileageMoneyWorkDayModel, StandaloneMileageReportSubmittedModel } from "../models/mileage-money";
import { AssignmentBasicModel } from "../models/assignment.model";

window.app.factory('MileageMoneyFactory', ['DBFactory', 'AuthService',
                                  function (DBFactory,   AuthService) {
  class MileageMoney {
    ebsDataId:      number;
    licensePlateId: number;
    assignment:     AssignmentBasicModel;

    startDate:      Date;
    endDate:        Date;
    workDays:       MileageMoneyWorkDay[];

    edited:         boolean;
    static COLECTION_NAME = 'mileage_money';
    constructor (data: StandaloneMileageReportModel) {
      this.assignment     = data.assignment;
      this.ebsDataId      = data.ebs_data_id;
      this.licensePlateId = data.license_plate_id;

      this.startDate      = this.parceDate(data.start_date);
      this.endDate        = this.parceDate(data.end_date);
      this.workDays       = data.work_days?.length ? data.work_days.map(day => new MileageMoneyWorkDay(day)) : null;

      this.edited         = data.edited;
    }

    get totalKm(): number {
      return this.workDays?.length ? Math.round((this.workDays.reduce((sum, val) => sum = sum + val.amountOfKm, 0) + Number.EPSILON) * 100) / 100 : 0;
    }

    parceDate(date: Date | string | number): Date {
      return date ? date instanceof Date ? date : new Date(date) : null;
    }

    toJSON(): StandaloneMileageReportModel {
      return Object.assign({}, {
        ebs_data_id:      this.ebsDataId                 ? this.ebsDataId                         : null,
        license_plate_id: this.licensePlateId            ? this.licensePlateId                    : null,
        assignment:       this.assignment                ? this.assignment                        : null,

        start_date:       this.startDate instanceof Date ? this.startDate.toISOString()           : null,
        end_date:         this.endDate   instanceof Date ? this.endDate.toISOString()             : null,

        work_days:        this.workDays?.length          ? this.workDays.map(day => day.toJSON()) : null,
        edited:           this.edited !== null           ? this.edited                            : null
      });
    }

    static getById(id): Promise<any> {
      return DBFactory.then((ds) => ds.db)
      .then((db) => db.get(MileageMoney.COLECTION_NAME, id));
    }

  }

  class MileageMoneySubmitted extends MileageMoney {
    id:                   number;
    userId:               number;
    createdAt:            Date;
    archivedAt:           Date;
    state:                string;

    reviewerNote:        string;
    internalReview:      string;
    customCalculatedSum: number;

    splitChildId:        number;
    constructor (data: StandaloneMileageReportSubmittedModel) {
      super(data);

      this.id                  = data.id;
      this.userId              = data.user_id || AuthService.userId || null;

      this.createdAt           = super.parceDate(data.created_at);
      this.archivedAt          = super.parceDate(data.archived_at);
      this.state               = data.state;

      this.reviewerNote        = data.reviewer_note;
      this.internalReview      = data.internal_review;
      this.customCalculatedSum = parseFloat(data.custom_calculated_sum);

      this.splitChildId        = data.split_child_id;
    }

    toJSON(): StandaloneMileageReportSubmittedModel {
      return Object.assign(super.toJSON(), {
        id:                    this.id                  ? this.id                       : null,
        user_id:               this.userId              ? this.userId                   : null,
        created_at:            this.createdAt           ? this.createdAt.toISOString()  : null,
        archived_at:           this.archivedAt          ? this.archivedAt.toISOString() : null,
        state:                 this.state               ? this.state                    : null,

        reviewer_note:         this.reviewerNote        ? this.reviewerNote             : null,
        internal_review:       this.internalReview      ? this.internalReview           : null,
        custom_calculated_sum: this.customCalculatedSum ? this.customCalculatedSum+''   : null,

        split_child_id:        this.splitChildId        ? this.splitChildId             : null
      });
    }

    save(): Promise<MileageMoneySubmitted> {
      return DBFactory.then((ds) => ds.db)
      .then((db) => db.put(MileageMoney.COLECTION_NAME, this.toJSON()))
      .then(() => this);
    }

  }

  class MileageMoneyWorkDay {
    mileageMoneyId:          number;
    date:                    Date;

    externalEmployeeAddress: string;
    assignmentAddress:       string;

    amountPerKm:             number;
    amountOfKm:              number;
    constructor (data: MileageMoneyWorkDayModel) {
      this.mileageMoneyId          = data.mileage_money_id;
      this.date                    = this.parceDate(data.date);

      this.externalEmployeeAddress = data.external_employee_address;
      this.assignmentAddress       = data.assignment_address;

      this.amountPerKm             = parseFloat(data.amount_per_km);
      this.amountOfKm              = parseFloat(data.amount_of_km);
    }

    private parceDate(date: Date | string | number): Date {
      return date ? date instanceof Date ? date : new Date(date) : null;
    }

    toJSON(): MileageMoneyWorkDayModel {
      return Object.assign({}, {
        mileage_money_id:          this.mileageMoneyId          ? this.mileageMoneyId          : null,
        date:                      this.date instanceof Date    ? this.date.toISOString()      : null,

        external_employee_address: this.externalEmployeeAddress ? this.externalEmployeeAddress : null,
        assignment_address:        this.assignmentAddress       ? this.assignmentAddress       : null,

        amount_per_km:             this.amountPerKm             ? this.amountPerKm+''          : null,
        amount_of_km:              this.amountOfKm              ? this.amountOfKm+''           : null
      });
    }

  }

  Object.defineProperty(MileageMoney, 'MileageMoneySubmitted', { value: MileageMoneySubmitted });
  Object.defineProperty(MileageMoney, 'MileageMoneyWorkDay',   { value: MileageMoneyWorkDay   });
  return MileageMoney;
}]);
